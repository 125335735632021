#login-background{
    background-image: url('https://eac.com.ar/wp-content/uploads/2022/03/facultas-sociales.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25%;
  }
  
  #login-form{
    margin-top: 150px;
  }
  
  .btn-primary{
    background-color: #49A5DF !important;
    border-color: #49A5DF !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  }
  
  .btn-primary:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
  }
  
  .btn-primary:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
  }
  
  .link-primary{
    color: #49A5DF !important;
    text-decoration: none !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  }
  
  .link-primary:focus{
    color: #0D72B5 !important;
  }
  
  .link-primary:hover{
    color: #0D72B5 !important;
  }
  
  .navbar-menu-not-active{
    text-decoration: none;
    background-color: #A21D28 !important;
    padding-top: 9px;
    padding-bottom: 11px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  }
  
  .navbar-menu-not-active:focus{
    text-decoration: "none";
    background-color: #e6414f !important;
    border-color: #e6414f !important;
  }
  
  .navbar-menu-not-active:hover{
    text-decoration: "none";
    background-color: #e6414f !important;
    border-color: #e6414f !important;
  }
  
  .navbar-menu-active{
    text-decoration: none;
    background-color: #e6414f !important;
    padding-top: 9px;
    padding-bottom: 11px;
  }
  
  .subnavbar-menu-not-active{
    text-decoration: none;
    background-color: #0D72B5 !important;
    padding-top: 9px;
    padding-bottom: 11px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    color: white;
  }
  
  .subnavbar-menu-not-active:focus{
    text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
    color: white;
  }
  
  .subnavbar-menu-not-active:hover{
    text-decoration: "none";
    background-color: #49A5DF !important;
    border-color: #49A5DF !important;
    color: white;
  }
  
  .subnavbar-menu-active{
    text-decoration: none;
    background-color: #49A5DF !important;
    padding-top: 9px;
    padding-bottom: 11px;
    color: white;
  }
  
  
  .cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
  }
  
  .cards.content{
    padding: 0 !important;
  }
  
  .content-table.head{
    text-align: center;
    border-bottom: 2px solid #A0A0A0;
    background-color: #EBEBEB;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
  }
  
  .content-table.rows{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 2px solid #EBEBEB;
  }
  
  .content-table.rows.middle{
    text-align: center;
  }
  
  .content-table.rows.right{
    text-align: right;
  }
  
  .content-table.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
  }
  
  .content-options.content{
    height: 72px;
    border-bottom: 3px solid #EBEBEB !important;
  }
  
  .content-options.rows.left{
    padding-left: 32px;
    width: 50%;
  }
  
  .content-options.rows.right{
    padding-right: 32px;
    text-align: right;
  }
  
  .column-title{
    background-color: #49A5DF;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 10px 10px 0px 0px;
    color: white
  }
  
  .column-content{
    background-color: #f9f9f9;
    padding: 16px;
    height: 100%;
  }
  
  .column-content-nh{
    background-color: #FAFAFA;
    padding: 16px;
  }
  
  .fc-col-header-cell-cushion {
    text-decoration: none !important;
  }
  
  .fc-daygrid-day-number{
    text-decoration: none !important;
  }
  
  .table-bordered>:not(caption)>*>*{
    border: none !important;
  }
  
  .file.element{
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
  }
  
  
  
  .statusCard{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 130px !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
  }
  
  .statusCard.green{
    border-left: 5px solid #28A745 !important;
  }
  
  .statusCard.yellow{
    border-left: 5px solid #FFC008 !important;
  }
  
  .statusCard.red{
    border-left: 5px solid #DC3545 !important;
  }
  
  .avatar-10{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .avatar-20{
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .avatar-30{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .avatar-60{
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  .content-body{
    padding-top: 30px;
  }

  .statusCard{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 130px !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
  }
  
  .statusCard.green{
    border-left: 5px solid #28A745 !important;
  }
  
  .statusCard.yellow{
    border-left: 5px solid #FFC008 !important;
  }
  
  .statusCard.red{
    border-left: 5px solid #DC3545 !important;
  }

  .statusCard.gray{
    border-left: 5px solid #6c6c6c !important;
  }

  .finalVoteCard{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 75px !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
  }

  .finalVoteCard.green{
    border-left: 5px solid #28A745 !important;
  }
  
  .finalVoteCard.yellow{
    border-left: 5px solid #FFC008 !important;
  }
  
  .finalVoteCard.red{
    border-left: 5px solid #DC3545 !important;
  }

  .finalVoteCard.gray{
    border-left: 5px solid #6c6c6c !important;
  }